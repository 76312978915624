// ui state module actions
import theApiCall from "@/hooks/api/the-api-call";
import {apiResponseHandler} from "@/hooks/api/api-helper-function";

export default {

    async isFutureLeaveBalanceEnabled(context) {
        let userApiId = (localStorage.userId) ? localStorage.userId : context.getters.userApiId
        const futureDate = new Date()
        futureDate.setMonth(futureDate.getMonth() + 1)
        const formattedFutureDate = futureDate.toISOString().split('T')[0]
        let response, responseData
        try {
            response = await theApiCall(`employees/${userApiId}/future_leave_balances/?date=${formattedFutureDate}`)
            responseData = await apiResponseHandler(response, 'Could not fetch future leave balance.', 'Future leave balance calc not found. [404]')
            context.commit('setIsFutureLeaveBalanceEnabled', true)
            return responseData
        } catch (error) {
            console.warn("Future leave balance functionality is not supported.", error.message)
            context.commit('setIsFutureLeaveBalanceEnabled', false)
        }
    },

}