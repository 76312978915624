<template>
    <div>
        <MDBSideNav
            v-model="isSideNav" id="side-nav"
            :modeBreakpoint="1200" :side="side" :over="over"
            contentSelector="#main-app-wrapper"
            :closeOnEsc="true"
            backdropStyle="position: fixed"
            :slim="isSideNavSlim" :slimWidth="36" @click="sideNavClickHandler" ref="theSideNav"
            :sidenavWidth="260"
            class="shadow-0"
        >
            <MDBSideNavMenu scrollContainer>
                <div class="menu-cont d-flex justify-content-center">
                    <div class="menu-content flex-grow-1">
                        <div id="my-dash-container" v-if="myDashCont">
                            <the-side-nav-badge @toggle-menu-dash="myDash = !myDash" :expanded="myDash">My Bento Dashboard</the-side-nav-badge>
                        
                            <div id="my-dash" class="mt-4" v-if="myDash">
                                <menu-item-profile/>
                                <menu-item-salary/>
                                <menu-item-essentials/>
                                <menu-item-reimbursements v-if="isEnabledReimbursements"/>
                                <menu-item-simple-benefit
                                    v-for="categoryObj in simpleBenefitsCategoryList" :key="categoryObj.category"
                                    :simple-benefit-title="categoryObj.categoryName" :simple-benefit-category-obj="categoryObj"
                                />
                            </div>
                        </div>
                        
                        <div id="team-dash-container" v-if="teamDashCont && (isLeaveApproverOrDownloader || isHrAdmin || isReimbursementApproverOrDownloader)">
                            <the-side-nav-badge :is-team-dash="true" @toggle-menu-dash="teamDash = !teamDash" :expanded="teamDash" class="mt-5" if>My Team Dashboard</the-side-nav-badge>
                        
                            <div id="team-dash" class="mt-4" v-if="teamDash">
                                <menu-item-team-admin v-if="showTeamAdminInterface"/>
                                <menu-item-team-leave-management v-if="isHrAdmin || isLeaveApproverOrDownloader"/>
                                <menu-item-team-reimbursements-management v-if="(isHrAdmin || isReimbursementApproverOrDownloader) && isEnabledReimbursements"/>
                            </div>
                        </div>
                
                    </div>
                </div>
            </MDBSideNavMenu>
        
            <div id="menu-toggler-cont" class="d-none d-xl-flex menu-toggler-cont d-flex align-items-center flex-nowrap justify-content-start">
                <div id="side-nav-toggler" class="d-inline-block text-center shadow-1-strong cursor-pointer" @click="toggleSideNavHandler(!isSideNavSlim)">
                    <svg width="9" height="16" viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg" id="little-chev-toggler">
                        <path d="M0.292893 0.292893C-0.0976311 0.683418 -0.0976311 1.31658 0.292893 1.70711L6.58579 8L0.292894 14.2929C-0.0976305 14.6834 -0.0976305 15.3166 0.292894 15.7071C0.683418 16.0976 1.31658 16.0976 1.70711 15.7071L8.70711 8.70711C9.09763 8.31658 9.09763 7.68342 8.70711 7.29289L1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893Z" fill="#212121"/>
                    </svg>
                </div>
                <div id="collapse-text" class="flex-grow-1 d-inline-block font-weight-500 cursor-pointer" @click="toggleSideNavHandler(!isSideNavSlim)">
                    Collapse
                </div>
            </div>
    
        </MDBSideNav>
    </div>
</template>

<script setup>
    import {
        MDBSideNav,
        MDBSideNavMenu,
    } from "mdb-vue-ui-kit"
    import TheSideNavBadge from "@/components/layouts/side-nav/side-nav-components/TheSideNavBadge.vue"
    import {ref, computed} from 'vue'
    import {useStore} from "vuex"
    import MenuItemEssentials from "@/components/layouts/side-nav/items-my-dash/MenuItemEssentials.vue"
    import MenuItemReimbursements from "@/components/layouts/side-nav/items-my-dash/MenuItemReimbursements.vue"
    import MenuItemSimpleBenefit from "@/components/layouts/side-nav/items-my-dash/MenuItemSimpleBenefit.vue"
    
    import MenuItemSalary from "@/components/layouts/side-nav/items-my-dash/MenuItemSalary.vue"
    import MenuItemProfile from "@/components/layouts/side-nav/items-my-dash/MenuItemProfile.vue"
    import MenuItemTeamAdmin from "@/components/layouts/side-nav/items-team-dash/MenuItemTeamAdmin.vue"
    import MenuItemTeamLeaveManagement from "@/components/layouts/side-nav/items-team-dash/MenuItemTeamLeaveManagement.vue"
    import MenuItemTeamReimbursementsManagement
        from "@/components/layouts/side-nav/items-team-dash/MenuItemTeamReimbursementsManagement.vue";
    const store = useStore()

    const isHrAdmin = computed(() => store.getters.isHrAdmin)
    const showTeamAdminInterface = computed(() => store.getters.showTeamAdminInterface)
    const isLeaveApproverOrDownloader = computed(() => store.getters.isLeaveApproverOrDownloader)
    const isReimbursementApproverOrDownloader = computed(() => store.getters.isReimbursementApproverOrDownloader)
    const isEnabledReimbursements = computed(() => store.getters.isEnabledReimbursements)
    const simpleBenefitsCategoryList = computed(() => store.getters.simpleBenefitsCategoryList)
    
    // isSideNav shows and hides the side nav as a whole (applicable to mobile)
    const isSideNav = computed({
        get: () => store.getters.isSideNav,
        set: (isSideNav) => store.commit('toggleIsSideNav', isSideNav)
    })
    // isSideNavSlim shows the slim navbar instead of the wide navbar.. not applicable to mobile
    const isSideNavSlim = computed({
        get: () => store.getters.isSideNavSlim,
        set: (isSlim) => store.commit('toggleIsSideNavSlim', isSlim)
    })
    
    // this changes at specified breakpoint -> :modeBreakpoint="1200"
    const side = ref(true)
    const over = ref(false)
    
    const myDashCont = ref(true)
    const myDash = ref(true)
    const teamDashCont = ref(true)
    const teamDash = ref(true)
    
    const theSideNav = ref(null)
    const sideNavClickHandler = (event) => {
        if (event.target.id === 'side-nav-toggler' || event.target.id === 'little-chev-toggler' || event.target.id === 'collapse-text') {
            return
        }
        if (isSideNavSlim.value) {
            toggleSideNavHandler(false)
        }
    }
    
    const toggleSideNavHandler = (isSlim) => store.commit('toggleIsSideNavSlim', isSlim)
</script>

<style lang="scss">
    @import "@/styles/global-scss/variables-and-mixins.scss";
    
    #side-nav {
        z-index: 9999;
        width: 252px !important;
        overflow: visible !important;
        border-right: 1px solid rgba($card-border-color, .75);
        
        &.sidenav-slim-collapsed {
            width: 40px !important;
            cursor: pointer;
            &:hover {
                #side-nav-toggler {
                    background-color: $primary;
                    opacity: 100;
                    #little-chev-toggler {
                        path {
                            fill: white;
                        }
                    }
                }
            }
        }
        
        .ps {
            background-color: lighten($gray-50, 1.5%);
        }
    }
    
    .sidenav-backdrop {
        cursor: pointer !important;
    }
    
    .menu-toggler-cont {
        border-top: 1px solid $gray-100;
        width: 146px;
        position: absolute;
        bottom: 0;
        left: 0;
        background-color: lighten($gray-50, 1.5%);
        padding: 10px 20px;
    }
    
    #side-nav-toggler {
        top: 88px;
        left: 240px;
        width: 26px;
        height: 26px;
        border-radius: 50%;
        background: $gray-100;
        
        #little-chev-toggler {
            width: 7px;
            transform: rotate(180deg);
            position: relative;
            left: -1px;
            path {
                fill: $gray-main;
            }
        }
        
        &:hover {
            background-color: $primary;
            #little-chev-toggler {
                path {
                    fill: white;
                }
            }
        }
        
        &:has(+ #collapse-text:hover) {
            background: $primary;
            #little-chev-toggler {
                path {
                    fill: white;
                }
            }
        }
        
        &:hover + #collapse-text {
            color: $primary;
        }
        
    }
    
    #collapse-text {
        padding-left: 14px;
        max-width: 120px;
        &:hover {
            color: $primary;
        }
    }
    
    .sidenav-slim-collapsed {
        .menu-toggler-cont {
            padding-left: 8px;
            padding-right: 0;
        }
        #side-nav-toggler {
            margin-right: 0;
            #little-chev-toggler {
                left: 0;
                transform: rotate(0);
            }
        }
        #collapse-text {
            display: none !important;
        }
    }
    
    .menu-cont {
        transition: .2s ease-in-out;
        opacity: 1;
        padding-top: 68px;
        padding-bottom: 30px;
    }
    
    .menu-content {
        padding: 30px 12px 0;
    }
    
    .sidenav-slim-collapsed {
        .menu-cont {
            opacity: 0;
            display: none;
        }
    }

</style>